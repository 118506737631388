<template>
  <div id="products">
    <Header2 nav="3" type="1" @search="getValue"></Header2>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-show="!showloading">
      <div class="banner">
        <img :src="bannerImg" />
      </div>

      <div class="sort" id="boxFixed" :class="isFixed ? 'tabsFixed' : ''">
        <div
          v-for="(item, index) in tabsList"
          :key="item.cid"
          class="sortItem"
          :class="sortActive == index ? 'sortActive' : ''"
          @click="changeSort(index, item.cid)"
        >
          {{ item.pname }}
        </div>
      </div>

      <!-- <div class="loading2" v-if="showloading2">
        <van-loading type="spinner" color="#206C47" size="24px" vertical>
          加载中...
        </van-loading>
      </div> -->

      <div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          :error.sync="error"
          error-text="请求失败"
        >
          <div class="list" :class="isFixed ? 'listActive' : ''">
            <div
              class="listItem"
              v-for="item in products"
              :key="item.id"
              @click="getDetails(item.id)"
            >
              <div class="pageimg">
                <img class="pageImg" :src="item.imgurl" />
              </div>
              <div class="pageName">{{ item.name }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header2 from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";
import { Icon, Loading, List } from "vant";
import qs from "qs";

export default {
  components: {
    Header2,
    Footer,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [List.name]: List,
  },
  data() {
    return {
      bannerImg: "",
      showloading: true,
      // showloading2: true,
      tabsList: [], //分类
      sortActive: 0, //分类高亮状态
      cid: 0, //当前的分类id
      titlePage: 1, //当前页
      total: 1, //数据总条数
      products: [], //商品列表数据
      loading: true,
      finished: false,
      error: false,
      isFixed: false, //是否吸顶
      offsetTop: 100000, //吸顶元素距离页面顶部的距离
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    // 轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 18 })
      .then((res) => {
        // console.log(res.data);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr[0].image;
          setTimeout(() => {
            this.showloading = false;
            // 计算吸顶元素到页面顶部的距离
            this.$nextTick(() => {
              let box = document.getElementById("boxFixed");
              this.offsetTop = box.offsetTop;
              // console.log("offsetTop", this.offsetTop);
            });
          }, 300);
        }
      });
    this.getSort();
  },
  methods: {
    // 获取分类
    getSort() {
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=search&m=class_index"
        )
        .then((res) => {
          // console.log(res.List);
          if (res.status == 1) {
            let tabsList = res.List;
            tabsList.unshift({ cid: "0", pname: "全部" });
            this.tabsList = tabsList;
            // console.log(this.tabsList);
            this.cid = this.tabsList[0].cid;
            this.getData();
          }
        });
    },

    // 获取商品列表
    getData() {
      if (this.cid == 0) {
        this.cid = "";   //cid为空则代表全部商品
      }
      let data1 = qs.stringify({
        page: this.titlePage, //当前页数
        cid: this.cid, //分类id
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=product&m=product_list",
          data1
        )
        .then((res) => {
          if (res.status == 1) {
            // console.log(res.pro);
            // this.products = res.pro;
            this.products = [...this.products, ...res.pro];
            this.total = res.total; //数据总条数
            this.loading = false;
            // this.showloading2 = false;
          }else{
            this.products = [];
            this.total = 0; //数据总条数
            this.loading = false;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },

    // 点击切换分类
    changeSort(index, id) {
      this.sortActive = index;
      this.cid = id;
      this.products = [];
      this.titlePage = 1; //当前页
      // this.showloading2 = true;
      this.loading = true;
      this.finished = false;
      this.error = false;
      this.getData();
    },

    // 下拉加载数据
    onLoad() {
      if (this.products.length >= this.total) {
        this.finished = true;
        this.loading = false;
      } else {
        this.titlePage += 1;
        this.getData();
      }
    },

    // 下拉页面，处理吸顶
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
      // console.log(scrollTop,this.offsetTop)
      this.isFixed = scrollTop + 44 > this.offsetTop ? true : false;
    },

    getValue(data1) {
      this.getSearch(data1);
    },

    getSearch(word) {
      let data1 = qs.stringify({
        word: word,
        page: 1,
        cid: this.cid,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=search&m=pro_search",
          data1
        )
        .then((res) => {
          if (res.code == 0) {
            this.products = [];
            this.total = 0;
          }
          if (res.status == 1) {
            // console.log(res);
            this.products = res.pro;
            this.total = res.total; //数据总条数
          }
        });
    },

    // 跳转到商品详情页
    getDetails(id) {
      // console.log(id);
      this.$router.push({ path: `/productDetail?id=${id}` });
    },
  },
};
</script>

<style lang="less" scoped>
#products {
  box-sizing: border-box;
}
.container {
  margin-top: 2.6875rem;
}
.banner {
  //margin-top: 2.75rem;
  font-size: 0;
  // height: 15rem;
  img {
    width: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}
.loading2 {
  width: 100vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort {
  height: 2.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-shadow: 0 0 5px 1px #d2d2d2;
  background-color: #fff;
  width: 100%;
  .sortItem {
    color: #666666;
    font-size: 0.8125rem;
    // margin-right: 3rem;
    border-bottom: 2px solid #fff;
    // width: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // &:nth-last-child(1) {
    //   margin-right: 0;
    // }
  }
  .sortActive {
    color: #206c47;
    border-bottom: 2px solid #206c47;
    padding: 0.5rem 0;
  }
}
.tabsFixed {
  position: fixed;
  top: 2.5rem;
  z-index: 99;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.875rem; //30px
  // margin-top: 4.375rem;
  .listItem {
    margin-bottom: 1.875rem;
    margin-left: 1rem;
    width: calc((100vw - 3rem) / 2);
    box-shadow: 0 0 5px 1px #e7e7e7;
    .pageimg {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .pageImg {
        margin-top: 0.125rem;
        width: calc((100vw - 3rem) / 2 - 4px);
      }
    }
    .pageName {
      color: #206c47;
      font-size: 14px;
      background-color: #fff;
      height: 7.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.listActive {
  margin-top: 4.75rem;
}

/deep/ .van-list__finished-text {
  margin-bottom: 1.5rem;
}
/deep/ .van-list__placeholder {
  margin-bottom: 1.5rem;
}
</style>
